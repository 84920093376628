// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Icon__Jsx3 = require("../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var MarkupHelpers = require("../../reason/common/MarkupHelpers/MarkupHelpers.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../reason/common/Theme/Theme__Color__Vars.bs.js");
var VerifiedAgentCardV5 = require("./VerifiedAgentCardV5.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RemovedSharedPropertyV5_removedSharedProperty_graphql = require("../../__generated__/RemovedSharedPropertyV5_removedSharedProperty_graphql.bs.js");

var convertFragment = RemovedSharedPropertyV5_removedSharedProperty_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(RemovedSharedPropertyV5_removedSharedProperty_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, RemovedSharedPropertyV5_removedSharedProperty_graphql.node, convertFragment);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function RemovedSharedPropertyV5(props) {
  var match = RescriptRelay_Fragment.useFragment(RemovedSharedPropertyV5_removedSharedProperty_graphql.node, convertFragment, props.removedSharedProperty);
  var sender = match.sender;
  return JsxRuntime.jsxs(MarkupHelpers.Container.make, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Icon__Jsx3.make, {
                                          icon: "AttentionAlt",
                                          size: 60,
                                          color: Theme__Color__Vars.biscay
                                        }),
                                    className: "pl-4 pr-6"
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("h3", {
                                                  children: "This property is no longer available",
                                                  className: "text-lg font-medium leading-6 text-gray-900"
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: "The property has been removed from TAN. For more information, please contact your agent.",
                                                  className: "mt-2 text-gray-500"
                                                })
                                          ],
                                          className: "mb-6"
                                        })
                                  })
                            ],
                            className: "flex flex-row"
                          }),
                      className: "content-box p-4 mt-6 border rounded"
                    }),
                sender !== undefined ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(VerifiedAgentCardV5.make, {
                                    chapter: Core__Option.map(match.chapter, (function (c) {
                                            return c.fragmentRefs;
                                          })),
                                    agent: sender.fragmentRefs
                                  }),
                              className: "container py-6"
                            }),
                        className: "bg-white h-full mt-6"
                      }) : null
              ]
            });
}

var make = RemovedSharedPropertyV5;

exports.Fragment = Fragment;
exports.make = make;
/* Icon__Jsx3 Not a pure module */
