// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Icon__Jsx3 = require("../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var MarkupHelpers = require("../../reason/common/MarkupHelpers/MarkupHelpers.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../reason/common/Theme/Theme__Color__Vars.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var TerminatedAgentSharedPropertyV5_sharedProperty_graphql = require("../../__generated__/TerminatedAgentSharedPropertyV5_sharedProperty_graphql.bs.js");

var convertFragment = TerminatedAgentSharedPropertyV5_sharedProperty_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(TerminatedAgentSharedPropertyV5_sharedProperty_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, TerminatedAgentSharedPropertyV5_sharedProperty_graphql.node, convertFragment);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function TerminatedAgentSharedPropertyV5(props) {
  var match = RescriptRelay_Fragment.useFragment(TerminatedAgentSharedPropertyV5_sharedProperty_graphql.node, convertFragment, props.terminatedAgentSharedProperty);
  var agentName = Core__Option.getOr(Core__Option.map(match.sender, (function (x) {
              return x.displayName;
            })), "");
  return JsxRuntime.jsx(MarkupHelpers.Container.make, {
              className: "mt-8",
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Icon__Jsx3.make, {
                                        icon: "AttentionAlt",
                                        size: 60,
                                        color: Theme__Color__Vars.biscay
                                      }),
                                  className: "pl-4 pr-6"
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx("h3", {
                                                children: "This property is no longer shared",
                                                className: "text-xl font-medium leading-6 text-gray-900"
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: "The property cannot be viewed as your agent " + agentName + " no longer has an active TAN subscription."
                                              })
                                        ],
                                        className: "mb-6 flex flex-col gap-4"
                                      })
                                })
                          ],
                          className: "flex"
                        }),
                    className: "bg-white border p-5 rounded"
                  })
            });
}

var make = TerminatedAgentSharedPropertyV5;

exports.Fragment = Fragment;
exports.make = make;
/* Icon__Jsx3 Not a pure module */
