// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../common/ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UnselectedUnionMemberError = require("../common/ErrorHandling/UnselectedUnionMemberError.bs.js");
var WithdrawnSharedPropertyLocation_sharedProperty_graphql = require("../../__generated__/WithdrawnSharedPropertyLocation_sharedProperty_graphql.bs.js");

var convertFragment = WithdrawnSharedPropertyLocation_sharedProperty_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(WithdrawnSharedPropertyLocation_sharedProperty_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, WithdrawnSharedPropertyLocation_sharedProperty_graphql.node, convertFragment);
}

var SharedPropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function WithdrawnSharedPropertyLocation$WithoutErrorBoundary(props) {
  var sharedProperty = RescriptRelay_Fragment.useFragment(WithdrawnSharedPropertyLocation_sharedProperty_graphql.node, convertFragment, props.sharedProperty);
  var match = sharedProperty.propertyLocation;
  var variant = match.NAME;
  var match$1;
  if (variant === "PropertyLocationWithVisibleAddress") {
    var match$2 = match.VAL;
    match$1 = [
      match$2.locationDisplay,
      match$2.address
    ];
  } else {
    match$1 = variant === "UnselectedUnionMember" ? UnselectedUnionMemberError.raise(match.VAL) : [
        match.VAL.locationDisplay,
        undefined
      ];
  }
  var address = match$1[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: match$1[0],
                      className: "text-gray-700 font-medium text-lg sm:text-xl"
                    }),
                address !== undefined ? JsxRuntime.jsx("div", {
                        children: address,
                        className: "text-gray-500 text-lg"
                      }) : null
              ],
              className: "space-y-sm sm:space-y-0 flex-col lg:flex-row items-baseline"
            });
}

var WithoutErrorBoundary = {
  make: WithdrawnSharedPropertyLocation$WithoutErrorBoundary
};

function WithdrawnSharedPropertyLocation(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "location",
              children: JsxRuntime.jsx(WithdrawnSharedPropertyLocation$WithoutErrorBoundary, {
                    sharedProperty: props.sharedProperty
                  })
            });
}

var make = WithdrawnSharedPropertyLocation;

exports.SharedPropertyFragment = SharedPropertyFragment;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* react/jsx-runtime Not a pure module */
