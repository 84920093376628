// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

function unwrap_fragment_property_ForSalePropertyV5_status(u) {
  var v = u.__typename;
  if (v === "WithdrawnProperty") {
    return {
            NAME: "WithdrawnProperty",
            VAL: u
          };
  } else {
    return {
            NAME: "UnselectedUnionMember",
            VAL: v
          };
  }
}

function wrap_fragment_property_ForSalePropertyV5_status(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

function unwrap_fragment_property_RentalPropertyV5_status(u) {
  var v = u.__typename;
  if (v === "WithdrawnProperty") {
    return {
            NAME: "WithdrawnProperty",
            VAL: u
          };
  } else {
    return {
            NAME: "UnselectedUnionMember",
            VAL: v
          };
  }
}

function wrap_fragment_property_RentalPropertyV5_status(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

function unwrap_fragment_property(u) {
  var v = u.__typename;
  switch (v) {
    case "ForSalePropertyV5" :
        return {
                NAME: "ForSalePropertyV5",
                VAL: u
              };
    case "RentalPropertyV5" :
        return {
                NAME: "RentalPropertyV5",
                VAL: u
              };
    default:
      return {
              NAME: "UnselectedUnionMember",
              VAL: v
            };
  }
}

function wrap_fragment_property(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

var fragmentConverter = {"__root":{"property_RentalPropertyV5_status":{"u":"fragment_property_RentalPropertyV5_status"},"property_RentalPropertyV5":{"f":""},"property_ForSalePropertyV5_status":{"u":"fragment_property_ForSalePropertyV5_status"},"property_ForSalePropertyV5":{"f":""},"property":{"u":"fragment_property"},"":{"f":""}}};

var fragmentConverterMap = {
  fragment_property_ForSalePropertyV5_status: unwrap_fragment_property_ForSalePropertyV5_status,
  fragment_property_RentalPropertyV5_status: unwrap_fragment_property_RentalPropertyV5_status,
  fragment_property: unwrap_fragment_property
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "WithdrawnSharedPropertyV5_property"
},
v2 = {
  "kind": "RequiredField",
  "field": {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "status",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v0/*: any*/)
        ],
        "type": "WithdrawnProperty",
        "abstractKey": null
      }
    ],
    "storageKey": null
  },
  "action": "THROW",
  "path": "property.status"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RelaySharedProperty_sharedProperty",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedPropertyMessage_sharedProperty"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedForSaleProperty_sharedProperty"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VerifiedAgentCardV5_sharedProperty"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRentalProperty_sharedProperty"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WithdrawnSharedPropertyV5_sharedProperty"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SharedForSaleProperty_property"
              },
              (v2/*: any*/)
            ],
            "type": "ForSalePropertyV5",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SharedRentalProperty_property"
              }
            ],
            "type": "RentalPropertyV5",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "property"
    }
  ],
  "type": "SharedPropertyV5",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.unwrap_fragment_property_ForSalePropertyV5_status = unwrap_fragment_property_ForSalePropertyV5_status;
exports.wrap_fragment_property_ForSalePropertyV5_status = wrap_fragment_property_ForSalePropertyV5_status;
exports.unwrap_fragment_property_RentalPropertyV5_status = unwrap_fragment_property_RentalPropertyV5_status;
exports.wrap_fragment_property_RentalPropertyV5_status = wrap_fragment_property_RentalPropertyV5_status;
exports.unwrap_fragment_property = unwrap_fragment_property;
exports.wrap_fragment_property = wrap_fragment_property;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
