// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"":{"f":""}}};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__DevicePixelRatio"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithdrawnSharedPropertyV5_property",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "firstImage",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "PropertyImageConnection",
      "kind": "LinkedField",
      "name": "images",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": "PropertyImageEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "alias": null,
                  "args": null,
                  "concreteType": "ImageAttachment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "height",
                          "value": 400
                        },
                        {
                          "kind": "Variable",
                          "name": "ratio",
                          "variableName": "__relay_internal__pv__DevicePixelRatio"
                        },
                        {
                          "kind": "Literal",
                          "name": "width",
                          "value": 400
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                "action": "THROW",
                "path": "firstImage.edges.node"
              }
            ],
            "storageKey": null
          },
          "action": "THROW",
          "path": "firstImage.edges"
        }
      ],
      "storageKey": "images(first:1)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WithdrawnSharedPropertyV5_propertyAttributes"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PropertyPrice_property"
    },
    (v0/*: any*/)
  ],
  "type": "PropertyV5",
  "abstractKey": "__isPropertyV5"
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
