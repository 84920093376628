// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var MarkupHelpers = require("../../reason/common/MarkupHelpers/MarkupHelpers.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var VerifiedAgentCardV5 = require("./VerifiedAgentCardV5.bs.js");
var SharedRentalProperty = require("./SharedRentalProperty.bs.js");
var SharedForSaleProperty = require("./SharedForSaleProperty.bs.js");
var SharedPropertyMessage = require("./SharedPropertyMessage.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var WithdrawnSharedPropertyV5 = require("./WithdrawnSharedPropertyV5.bs.js");
var UnselectedUnionMemberError = require("../common/ErrorHandling/UnselectedUnionMemberError.bs.js");
var RelaySharedProperty_sharedProperty_graphql = require("../../__generated__/RelaySharedProperty_sharedProperty_graphql.bs.js");

var convertFragment = RelaySharedProperty_sharedProperty_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(RelaySharedProperty_sharedProperty_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, RelaySharedProperty_sharedProperty_graphql.node, convertFragment);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function RelaySharedProperty(props) {
  var sharedProperty = RescriptRelay_Fragment.useFragment(RelaySharedProperty_sharedProperty_graphql.node, convertFragment, props.sharedProperty);
  var match = sharedProperty.property;
  var variant = match.NAME;
  var tmp;
  if (variant === "ForSalePropertyV5") {
    var p = match.VAL;
    var match$1 = p.status;
    var exit = 0;
    if (typeof match$1 === "object" && match$1.NAME === "WithdrawnProperty") {
      tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(WithdrawnSharedPropertyV5.make, {
                    property: p.fragmentRefs,
                    sharedProperty: sharedProperty.fragmentRefs
                  }),
              JsxRuntime.jsx(MarkupHelpers.Container.make, {
                    children: JsxRuntime.jsx(VerifiedAgentCardV5.FromSharedProperty.make, {
                          sharedProperty: sharedProperty.fragmentRefs
                        })
                  })
            ]
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(SharedPropertyMessage.make, {
                    sharedProperty: sharedProperty.fragmentRefs
                  }),
              JsxRuntime.jsx(SharedForSaleProperty.make, {
                    property: p.fragmentRefs,
                    sharedProperty: sharedProperty.fragmentRefs
                  }),
              JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx(MarkupHelpers.Container.make, {
                          className: "py-4",
                          children: JsxRuntime.jsx(VerifiedAgentCardV5.FromSharedProperty.make, {
                                sharedProperty: sharedProperty.fragmentRefs
                              })
                        }),
                    className: "bg-gray-100"
                  })
            ]
          });
    }
    
  } else if (variant === "UnselectedUnionMember") {
    tmp = UnselectedUnionMemberError.raise("Unknown property type " + match.VAL);
  } else {
    var p$1 = match.VAL;
    var match$2 = p$1.status;
    var exit$1 = 0;
    if (typeof match$2 === "object" && match$2.NAME === "WithdrawnProperty") {
      tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(WithdrawnSharedPropertyV5.make, {
                    property: p$1.fragmentRefs,
                    sharedProperty: sharedProperty.fragmentRefs
                  }),
              JsxRuntime.jsx(MarkupHelpers.Container.make, {
                    children: JsxRuntime.jsx(VerifiedAgentCardV5.FromSharedProperty.make, {
                          sharedProperty: sharedProperty.fragmentRefs
                        })
                  })
            ]
          });
    } else {
      exit$1 = 1;
    }
    if (exit$1 === 1) {
      tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(SharedPropertyMessage.make, {
                    sharedProperty: sharedProperty.fragmentRefs
                  }),
              JsxRuntime.jsx(SharedRentalProperty.make, {
                    property: p$1.fragmentRefs,
                    sharedProperty: sharedProperty.fragmentRefs
                  }),
              JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx(MarkupHelpers.Container.make, {
                          className: "py-4",
                          children: JsxRuntime.jsx(VerifiedAgentCardV5.FromSharedProperty.make, {
                                sharedProperty: sharedProperty.fragmentRefs
                              })
                        }),
                    className: "bg-gray-100"
                  })
            ]
          });
    }
    
  }
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: "pt-8 flex flex-col gap-12"
            });
}

var make = RelaySharedProperty;

exports.Fragment = Fragment;
exports.make = make;
/* MarkupHelpers Not a pure module */
