// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $dot$dot$slash$dot$dot$slash$dot$dot$slash = require("../../../");
var JsxRuntime = require("react/jsx-runtime");

function map_prop(x) {
  if (typeof x === "object") {
    return x.VAL.map(map_prop);
  } else if (x === "medium") {
    return "medium";
  } else if (x === "small") {
    return "small";
  } else if (x === "xSmall") {
    return "xSmall";
  } else {
    return "large";
  }
}

var Size = {
  map_prop: map_prop
};

var make = $dot$dot$slash$dot$dot$slash$dot$dot$slash.AgentInfo;

var JS = {
  make: make
};

function AgentInfo__Jsx3(props) {
  var __size = props.size;
  var size = __size !== undefined ? __size : "small";
  var size$1 = map_prop(size);
  return JsxRuntime.jsx(make, {
              name: props.name,
              avatar: props.avatar,
              brokerage: props.brokerage,
              onClick: props.onClick,
              size: size$1,
              wrapName: props.wrapName,
              wrapAvatar: props.wrapAvatar,
              children: props.children
            });
}

var make$1 = AgentInfo__Jsx3;

exports.Size = Size;
exports.JS = JS;
exports.make = make$1;
/* make Not a pure module */
