import React from 'react'
import Page from 'v5/sharing/RelaySharedPropertyPage.bs'
import { useRouter } from 'next/router'

function SharedPropertyPage() {
  const router = useRouter()
  const { id } = router.query

  return <Page id={id} />
}

export default SharedPropertyPage
