import React from 'react'

import styled from 'styled-components'

import { Container } from 'components/MarkupHelpers'

import LogoDesktop from '../../../../../assets/images/logos/logo-desktop.svg'
import LogoTablet from '../../../../../assets/images/logos/logo-mobile.svg'

// The Next image is not compatible with picture elements
/* eslint-disable @next/next/no-img-element, @next/next/no-html-link-for-pages */

const StyledHeader = styled.header`
  background: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.alto};
`

const StyledHeaderInner = styled.div`
  display: flex;
  margin-bottom: 0;
  align-items: center;
  justify-content: space-between;
  height: 67px;
`

const ClientPageHeader = () => (
  <StyledHeader>
    <Container>
      <StyledHeaderInner>
        <a href="/">
          <picture>
            <source srcSet={LogoDesktop} media="(min-width: 992px)" />
            <img alt="logo" src={LogoTablet} />
          </picture>
        </a>
      </StyledHeaderInner>
    </Container>
  </StyledHeader>
)

export default ClientPageHeader
